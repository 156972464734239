<template>
    <div>
        <div style="height: 20px;"></div>
        <!--详情-->
        <el-row>
            <el-col :span="16">
                <el-form v-loading="loading" size="medium"
                         :label-width="this.env.label_width">
                    <el-form-item label="姓名">
                        <span class="form-span-text">{{ info.user_name }}</span>
                    </el-form-item>
                    <el-form-item label="性别">
                        <el-tag size="mini" v-if="info.gender===0" type="info">未知</el-tag>
                        <el-tag size="mini" v-if="info.gender===1">男</el-tag>
                        <el-tag size="mini" v-if="info.gender===2" type="danger">女</el-tag>
                    </el-form-item>
                    <el-form-item label="昵称">
                        <span class="form-span-text">{{ info.user_nick }}</span>
                    </el-form-item>
                    <el-form-item label="头像照片">
                        <Qnupload v-model="info.portrait" :sum="1" :readonly='true'/>
                    </el-form-item>
                    <el-form-item label="照片">
                        <Qnupload v-model="info.photo" :sum="9" :readonly='true'/>
                    </el-form-item>
                    <el-form-item label="微信头像">
                        <Qnupload v-model="info.avatar_url" :sum="1" :readonly='true'/>
                    </el-form-item>
                    <el-form-item label="微信二维码">
                        <Qnupload v-model="info.coach_qrcode" :sum="1" :readonly='true'/>
                    </el-form-item>
                    <el-form-item label="手机号">
                        <span class="form-span-text">{{ info.phone }}</span>
                    </el-form-item>
                    <el-form-item label="身份证号码">
                        <span class="form-span-text">{{ info.identity_card }}</span>
                    </el-form-item>
                    <el-form-item label="身份证照片">
                        <Qnupload v-model="info.identity_card_img" :sum="2" :readonly='true'/>
                    </el-form-item>
                    <el-form-item label="资质">
                        <span class="form-span-text">{{ info.qualifications }}</span>
                    </el-form-item>
                    <el-form-item label="资质照片">
                        <Qnupload v-model="info.qualifications_img" :sum="2" :readonly='true'/>
                    </el-form-item>
                    <el-form-item label="教学年限">
                        <span class="form-span-text">{{ info.years }}年</span>
                    </el-form-item>
                    <el-form-item label="擅长">
                        <span class="form-span-text">{{ info.be_good_at }}</span>
                    </el-form-item>
                    <el-form-item label="简介">
                        <el-card class="box-card">
                            <span class="form-span-text">{{ info.introduction }}</span>
                        </el-card>
                    </el-form-item>
                    <el-form-item label="地址">
                        <span class="form-span-text">{{ info.city }}{{ info.address }}</span>
                    </el-form-item>
                    <el-form-item label="创建时间">
                        <span class="form-span-text">{{ info.create_time }}</span>
                    </el-form-item>
                    <el-form-item label="更新时间">
                        <span class="form-span-text">{{ info.update_time }}</span>
                    </el-form-item>

                    <el-form-item>
                        <el-button size="medium" @click="isreturn">返回</el-button>
                    </el-form-item>
                </el-form>
            </el-col>
        </el-row>
    </div>
</template>
<script>
import Qnupload from '@/components/Qnupload'

export default {
    components: {
        Qnupload,
    },
    data() {
        return {
            loading: true,
            coach_uuid: '',
            info: {},
        }
    },
    // 创建
    created() {
        this.init()
    },
    // 安装
    mounted() {
    },
    methods: {
        // 初始化
        init() {
            let coach_uuid = this.$route.query.coach_uuid
            if (coach_uuid !== undefined) {
                this.coach_uuid = coach_uuid
                this.getinfo()
            } else {
                this.$message({
                    message: '无查找对象',
                    duration: this.env.message_error,
                    onClose: () => {
                        this.$router.push({path: '/coach/coachapply'})    // 返回列表
                    }
                });
            }
        },
        // 获取教练详情信息
        getinfo() {
            let postdata = {
                api_name: "coach.coachapply.getinfo",
                token: this.Tool.get_l_cache('token'),
                coach_uuid: this.coach_uuid
            }
            this.loading = true
            this.Tool.post_data('oss', postdata, (json) => {
                this.loading = false
                if (json.code === 0) {
                    this.info = json.data
                } else {
                    this.Tool.errormes(json)
                }
            })
        },

        // 返回
        isreturn() {
            // this.$router.push({path: '/course/course'})
            this.$router.go(-1)
        },
    }
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
